import {ChangeEvent, FC, useCallback, useState} from 'react'
import {debounce} from '../../utils'

interface SearchFieldProps {
  onSearch: (value: string) => void;
}

const SearchField: FC<SearchFieldProps> = ({onSearch}) => {
  const [value, setValue] = useState('')

  const debouncedSearch = useCallback(debounce((searchValue: string) => {
    onSearch(searchValue)
  }, 500), [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setValue(value)
    debouncedSearch(value)
  }

  return (
    <>
      <p className='text-text-secondary-3 text-sm font-medium'>
        Search:
      </p>
      <input
        type='text'
        className='input input-bordered w-full'
        placeholder='Search'
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

export default SearchField
