import {FC, ReactElement, useEffect} from 'react'
import {createBrowserRouter, Navigate, useLocation, useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import {PAGES} from '../constants'
import ResultsPage from '../pages/Results'
import ExamsPage from '../pages/Exams'
import DoctorMeetingPage from '../pages/DoctorMeeting'
import LoginPage from '../pages/Login'
import LinkExpiredPage from '../pages/LinkExpired'
import {isResponseInterceptorInstalled, setupResponseInterceptors} from '../api/apiClient'
import PatientMeetingPage from '../pages/PatientMeeting'

interface RequireAuthProps {
  children: ReactElement;
}

const RequireAuth: FC<RequireAuthProps> = ({children}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const token = Cookies.get('token')

  useEffect(() => {
    if (!isResponseInterceptorInstalled()) setupResponseInterceptors(navigate)
  }, [])

  if (!token) {
    return <Navigate to={PAGES.login} state={{from: location}} replace/>
  }
  return children
}

const AuthRedirect: FC = () => {
  const location = useLocation()
  const token = Cookies.get('token')

  if (token) {
    return <Navigate to={PAGES.results} replace/>
  }

  if (!token && location.pathname !== PAGES.login) {
    return <Navigate to={PAGES.login} state={{from: location}} replace/>
  }

  return null
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthRedirect/>,
  },
  {
    path: PAGES.login,
    element: <LoginPage/>,
  },
  {
    path: PAGES.results,
    element: <RequireAuth><ResultsPage/></RequireAuth>,
  },
  {
    path: PAGES.exams,
    element: <RequireAuth><ExamsPage/></RequireAuth>,

  },
  {
    path: PAGES.doctorMeeting,
    element: <RequireAuth><DoctorMeetingPage/></RequireAuth>,
  },
  {
    path: PAGES.patientMeeting,
    element: <PatientMeetingPage/>,
  },
  {
    path: PAGES.linkExpired,
    element: <RequireAuth><LinkExpiredPage/></RequireAuth>,
  },
])
