import React from 'react'
import {RouterProvider} from 'react-router-dom'
import {Toaster} from 'react-hot-toast'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient, router} from './utils'
import './App.css'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <RouterProvider router={router}/>
        <Toaster position='top-center'/>
      </>
    </QueryClientProvider>
  )
}

export default App
