import {FC} from 'react'
import QuestionItem from './QuestionItem'
import {CreatedQuestion} from '../../types'

interface QuestionListProps {
  examId: string;
  questionList: CreatedQuestion[];
}

const QuestionList: FC<QuestionListProps> = ({examId, questionList}) => {
  return (
    <div className='flex flex-col w-full gap-4 justify-between'>
      <div className='font-medium'>Questions</div>
      {!questionList.length && <div>-</div>}
      <div className='flex flex-col gap-2'>
        <div className='w-full flex flex-col gap-2'>
          {questionList.map((question) => (
            <QuestionItem examId={examId} question={question} key={question.id}/>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuestionList
