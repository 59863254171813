import {FC, useState} from 'react'
import QuestionNote from '../QuestionNote'
import {GoPlus} from 'react-icons/go'
import {CreatedQuestion} from '../../../types'

interface QuestionItemProps {
  examId: string;
  question: CreatedQuestion;
}

const QuestionItem: FC<QuestionItemProps> = ({examId, question}) => {
  const [add, setAdd] = useState(false)

  const handleAddExceptions = () => setAdd(true)
  const handleCancelQuestionNote = () => setAdd(false)

  return (
    <>
      <div className='w-full shadow p-2 rounded-xl border flex items-center justify-between'>
        {question.position + 1}. {question.title}
      </div>
      {question.note || add ? (
        <QuestionNote
          examId={examId}
          question={question}
          note={question.note}
          onCancel={handleCancelQuestionNote}
        />
      ) : (
        <button
          onClick={handleAddExceptions}
          className='text-text-primary-2 text-sm font-medium self-end flex items-center gap-1 underline btn-ghost px-2.5'
        >
          <GoPlus/>
          Add Reasons &amp; Exceptions
        </button>
      )}
    </>
  )
}

export default QuestionItem
