import {FC} from 'react'
import cn from 'classnames'

interface VideoCallLoaderProps {
  className?: string;
}

const VideoCallLoader: FC<VideoCallLoaderProps> = ({className}) => {
  return (
    <div className={cn('flex flex-col items-center justify-center', className)}>
      <div className='flex justify-between items-center mb-3 sm:mb-5 gap-3 sm:gap-5'>
        <div className='w-[18px] sm:w-6 xl:w-7 h-[18px] sm:h-6 xl:h-7 bg-[#6160B0] rounded-full animate-pulse1'></div>
        <div className='w-7 h-7 bg-[#EB77A6] rounded-full animate-pulse2'></div>
        <div className='w-7 h-7 bg-[#E7EEFF] rounded-full animate-pulse3'></div>
      </div>
      <p className='text-base sm:text-lg xl:text-xl'>
        Loading video call
      </p>
    </div>
  )
}

export default VideoCallLoader
