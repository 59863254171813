export enum SortBy {
  Title = 'title',
  PatientName = 'patientName',
  Date = 'date',
  Status = 'status'
}

export enum AssessmentAction {
  Activate = 'activate',
  Deactivate = 'deactivate',
  AddNote = 'addNote',
  UpdateNote = 'updateNote',
  RemoveNote = 'removeNote',
  Order = 'order',
  SaveQuestion = 'saveQuestion',
}

export enum AppointmentAction {
  SendAnswers = 'sendAnswers',
  ChangeStatus = 'changeStatus',
}

export enum AppointmentStatus {
  New = 'new',
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  Cancelled = 'cancelled'
}

export enum Action {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  ActivateDeactivate = 'activate/deactivate'
}
