import {FC, Fragment, useEffect, useState} from 'react'
import RCDropdown from 'rc-dropdown'
import Menu, {Divider, Item as MenuItem} from 'rc-menu'
import {IoIosArrowDown} from 'react-icons/io'
import {MenuInfo} from 'rc-menu/lib/interface'
import cn from 'classnames'

interface DropdownProps {
  items: { name: string, id?: number, value?: string }[];
  onChange?: (value: any) => void;
  selected?: string;
  placeholder?: string;
}

const Dropdown: FC<DropdownProps> = ({
  items,
  selected = '',
  placeholder = '',
  onChange,
}) => {
  const [selectedItem, setSelectedItem] = useState('')

  useEffect(() => {
    setSelectedItem(selected)
  }, [selected])

  const handleChange = (e: MenuInfo) => {
    setSelectedItem(items[+e.key].name)
    onChange?.(items[+e.key].value)
  }

  const menu = (
    <Menu className='primary-dropdown-menu'>
      {items.map((item, i) => (
        <Fragment key={i}>
          <MenuItem
            className='primary-dropdown-menu-item'
            onClick={handleChange}
            key={i}
          >
            {item.name}
          </MenuItem>
          {items.length > i + 1 && <Divider className='w-full'/>}
        </Fragment>
      ))}
    </Menu>
  )

  return (
    <RCDropdown
      overlay={menu}
      trigger={['click']}
      animation='slide-up'
    >
      <button className='primary-dropdown-btn'>
        <div className={cn('w-[90%] block truncate', {'text-item-color font-medium': !!selectedItem})}>
          {selectedItem || placeholder}
        </div>
        <div className='absolute inset-y-0 right-0 flex items-center mr-2.5'>
          <IoIosArrowDown/>
        </div>
      </button>
    </RCDropdown>
  )
}

export default Dropdown
