import {Logo} from '../../assets/images'
import VideoCallLoader from '../Loader/VideoCallLoader'
import Footer from '../Footer'

const ProviderConnectionScreen = () => {
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-between pb-[25px] custom-bg'>
      <div className='pt-10 sm:pt-[100px] xl:pt-[157px] px-8 flex flex-col justify-center items-center'>
        <Logo
          className='w-[181px] sm:w-[250px] xl:w-[334px] h-[91px] mb-10 sm:mb-[80px] xl:mb-[120px] text-accent-primary-2'
        />
        <h2
          className='mb-[30px] text-accent-primary-2 text-lg sm:text-xl md:text-2xl xl:text-3xl font-medium text-center'
        >
          You are now being connected with a licensed provider from SkinnyRx
        </h2>
        <p className='mb-[62px] text-[#1A0053] max-w-[680px] text-base sm:text-lg xl:text-[22px] text-center'>
          This brief video call will determine your eligibility for an online prescription for GLP-1. Please refrain
          from closing this tab to complete your order.
        </p>
        <VideoCallLoader className='mb-10'/>
      </div>
      <div className='mx-10 w-[calc(100%-80px)]'>
        <Footer/>
      </div>
    </div>
  )
}

export default ProviderConnectionScreen
