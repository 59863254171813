import React, {FC, useState} from 'react'
import {JitsiMeeting} from '@jitsi/react-sdk'
import {LuLoader2} from 'react-icons/lu'
import toast from 'react-hot-toast'

interface JitsiMeetingComponentProps {
  jwtToken: string;
  roomName: string;
  onReadyToClose?: () => void;
  onVideoConferenceJoined?: () => void;
  onDisplayNameChange?: (name: string) => void;
  onLoaded?: () => void;
}

const options = {
  configOverwrite: {
    disableDeepLinking: true,
    disableInviteFunctions: true,
    disableThirdPartyRequests: true,
    disablePolls: true,
    disableSelfViewSettings: true,
    disableSelfView: false,
    disableModeratorIndicator: true,
    hideConferenceSubject: true,
    disabledNotifications: ['notify.moderator'],
  },
  interfaceConfigOverwrite: {
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    CLOSE_PAGE_GUEST_HINT: false,
    DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
    DISPLAY_WELCOME_PAGE_CONTENT: false,
    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
    GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
    HIDE_INVITE_MORE_HEADER: true,
    TOOLBAR_BUTTONS: [
      'microphone', 'camera', 'fullscreen', 'hangup', 'chat',
    ],
  },
  getIFrameRef: (iframeRef: HTMLDivElement) => {
    iframeRef.style.height = '640px'
    iframeRef.style.width = '100%'
    iframeRef.style.overflow = 'hidden'
    iframeRef.style.borderRadius = '12px'
  },
}

const JitsiMeetingComponent: FC<JitsiMeetingComponentProps> = ({
  jwtToken,
  roomName,
  onReadyToClose,
  onVideoConferenceJoined,
  onDisplayNameChange,
  onLoaded,
}) => {
  const [meetingEnded, setMeetingEnded] = useState(false)

  const handleApiReady = (api: any) => {
    setTimeout(() => onLoaded?.(), 3000)


    api.addEventListener('videoConferenceJoined', () => onVideoConferenceJoined?.())

    api.addEventListener('displayNameChange', (event: any) => {
      const {displayname} = event
      onDisplayNameChange?.(displayname)
    })

    api.addEventListener('errorOccurred', (error: any) => {
      toast.error(`Error occurred: ${error.error.message || 'error'}`)
    })
  }

  const handleReadyToClose = () => {
    onReadyToClose?.()
    setMeetingEnded(true)
  }

  return (
    <div className='w-full h-full bg-[#040404] rounded-xl relative'>
      {meetingEnded ? (
        <div className='w-full h-full flex flex-col justify-center items-center gap-6 rounded-xl'>
          <h1 className='text-3xl text-white font-medium'>
            Meeting has ended
          </h1>
          <p className='text-base text-white font-medium mb-10'>
            Thank you for participating!
          </p>
        </div>
      ) : (
        <div className='absolute z-0 w-full h-full flex justify-center items-center'>
          <JitsiMeeting
            {...options}
            domain={process.env.REACT_APP_JITSI_DOMAIN}
            roomName={roomName}
            jwt={jwtToken}
            spinner={() => <LuLoader2 className='w-14 h-14 text-accent-light animate-spin '/>}
            onApiReady={handleApiReady}
            onReadyToClose={handleReadyToClose}
          />
        </div>
      )}
    </div>
  )
}

export default JitsiMeetingComponent
