import {useEffect, useState} from 'react'
import toast from 'react-hot-toast'
import {GoPlus} from 'react-icons/go'
import DashboardLayout from '../layouts/DashboardLayout'
import AddExamModal from '../components/Modal/AddExamModal'
import Table from '../components/Table'
import ExamItemActions from '../components/ExamItemActions'
import Loader from '../components/Loader/DefaultLoader'
import Pagination from '../components/Pagination'
import {DEFAULT_PAGINATION, initialParams} from '../constants'
import {useAssessmentsList} from '../hooks'
import cn from 'classnames'

const columns = [
  {accessorKey: 'id', header: 'Id', size: 50},
  {accessorKey: 'title', header: 'Title', size: 300},
  {accessorKey: 'questionsCount', header: 'Question Count', size: 230},
  {
    accessorKey: 'active', header: 'Active Exam', size: 200, cell: ({row}: any) => (
      <div
        className={cn('rounded-xl inline-block px-2 border text-[13px]',
          row.original.active ? 'border-bg-green bg-bg-green text-text-info' : 'border-bg-red bg-bg-red text-text-error-2',
        )}
      >
        {row.original.active ? 'visible' : 'hidden'}
      </div>
    ),
  },
  {
    accessorKey: 'actions', header: 'Actions', size: 160, cell: ({row}: any) => (
      <ExamItemActions item={row}/>
    ),
  },
]

const ExamsPage = () => {
  const [params, setParams] = useState(initialParams)
  const [showAddExamModal, setShowAddExamModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const {
    data,
    error,
    isFetching,
    isError,
  } = useAssessmentsList(params)

  useEffect(() => {
    if (!isFetching) setIsLoading(false)
  }, [isFetching])

  useEffect(() => {
    if (isError) {
      toast.error(error.message)
    }
  }, [isError])

  useEffect(() => {
    if (!isFetching && data?.items && !data.items.length && params.page > 1) {
      setParams(prev => ({...prev, page: params.page - 1}))
    }
  }, [data?.items])

  const handleAddExam = () => {
    setShowAddExamModal(true)
  }

  const handlePagination = (page: number) => {
    setParams(prev => ({...prev, page}))
  }

  return (
    <DashboardLayout>
      <>
        {showAddExamModal && (
          <AddExamModal
            isOpen={showAddExamModal}
            closeModal={() => setShowAddExamModal(false)}
          />
        )}
        <div className='flex items-center justify-between w-full gap-2'>
          <h1 className='text-xl md:text-3xl font-medium text-text-primary-3'>
            Exams List
          </h1>
          <button className='btn btn-primary gap-2' onClick={handleAddExam}>
            <GoPlus/>
            Add Exam
          </button>
        </div>
        {isLoading ? <Loader/> : data && (
          <div className='overflow-auto bg-transparent p-6 shadow-md ring-1 ring-border-light rounded-xl'>
            <div className='pb-12'>
              <Table columns={columns} data={data.items}/>
              {data.items.length ? '' : (
                <div className='text-gray-300 font-semibold text-2xl py-8 text-center mt-24'>
                  No Data
                </div>
              )}
            </div>
            {!!data.pagination.total && data.pagination.total > DEFAULT_PAGINATION.LIMIT && (
              <Pagination
                total={data.pagination.total}
                currentPage={data.pagination.currentPage}
                lastPage={data.pagination.lastPage}
                onPageChange={handlePagination}
              />
            )}
          </div>
        )}
      </>
    </DashboardLayout>
  )
}

export default ExamsPage
