import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CSSTransition } from "react-transition-group";
import { LuLoader2 } from "react-icons/lu";
import Checkbox from "../Checkbox";
import DraggableExamQuestionsList from "../DraggableExamQuestionsList";
import { AssessmentData, NewQuestion } from "../../types";
import { AssessmentAction, MAX_QUESTIONS } from "../../constants";
import {
  useCreateAssessmentQuestion,
  useDeleteAssessmentQuestion,
  useUpdateAssessment,
} from "../../hooks";
import { UniqueIdentifier } from "@dnd-kit/core";

export interface FullExamData extends AssessmentData {
  id: number;
  questionValue?: string;
  exam_questions?: string;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Please enter exam title"),
  questionValue: Yup.string(),
  questions: Yup.array().required("Please add questions"),
  note: Yup.string().nullable(),
});

interface ExamFormProps {
  mode: "add" | "edit";
  onSubmit: (data: FullExamData) => void;
  isPending?: boolean;
  data?: any;
  id?: string;
}

const ExamForm: FC<ExamFormProps> = ({
  mode,
  id,
  data,
  isPending,
  onSubmit,
}) => {
  const titleInputRef = useRef<HTMLInputElement>(null);
  const titleErrorRef = useRef(null);
  const questionRef = useRef(null);

  const [assessmentAction, setAssessmentAction] = useState<
    AssessmentAction | undefined
  >(undefined);

  const { mutate: updateExam } = useUpdateAssessment(assessmentAction);

  const {
    mutate: createQuestion,
    isSuccess: isSuccessCreatedQuestion,
    isPending: isPendingCreatedQuestion,
  } = useCreateAssessmentQuestion();

  const { mutate: deleteQuestion, isPending: isPendingDeleteQuestion } =
    useDeleteAssessmentQuestion();

  useEffect(() => {
    if (isSuccessCreatedQuestion) setFieldValue("questionValue", "");
  }, [isSuccessCreatedQuestion]);

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  const initialValues: FullExamData = {
    id: data?.id || 0,
    title: data?.title || "",
    attachmentsRequired: !!data?.attachmentsRequired,
    includeOptionalIntakeForm: data?.includeOptionalIntakeForm ?? true,
    asynchronous: !!data?.asynchronous,
    active: data?.active ?? true,
    questionValue: "",
    questions: data?.questions || [],
    exam_questions: "",
    note: data?.note || "",
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit({
        ...values,
        questionValue: undefined,
        exam_questions: undefined,
      });
    },
  });

  const handleAddQuestion = () => {
    if (!values.questionValue) return;

    const position = values.questions.length;

    if (mode === "add") {
      setFieldValue("questions", [
        ...values.questions,
        {
          id: Date.now(),
          position,
          title: values.questionValue,
        },
      ]);
      setFieldValue("questionValue", "");
    } else {
      const id = data.id as string;
      createQuestion({
        id,
        data: {
          title: values.questionValue,
          id: Date.now(),
          position,
        },
      });
    }
  };

  const handleIncludeOptionalChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && values.asynchronous) {
      setFieldValue("includeOptionalIntakeForm", true);
      setFieldValue("asynchronous", false);
      return;
    }
    handleChange(e);
  };

  const handleAsynchronousChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && values.includeOptionalIntakeForm) {
      setFieldValue("asynchronous", true);
      setFieldValue("includeOptionalIntakeForm", false);
      return;
    }
    handleChange(e);
  };

  const handleUpdateQuestions = (questions: NewQuestion[]) => {
    setFieldValue("questions", questions);
  };

  const handleSaveQuestion = (questions: NewQuestion[]) => {
    setAssessmentAction(AssessmentAction.SaveQuestion);
    id && updateExam({ id, data: { questions } });
  };

  const handleSaveOrder = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAssessmentAction(AssessmentAction.Order);
    id && updateExam({ id, data: { questions: values.questions } });
  };

  const handleDeleteQuestion = (questionId: UniqueIdentifier) => {
    deleteQuestion({ id: data.id, questionId: questionId.toString() });
  };

  const handleQuestionKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddQuestion();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 md:gap-4">
      <div className="flex flex-col">
        <label htmlFor="title" className="font-medium">
          Exam Title
        </label>
        <input
          ref={titleInputRef}
          id="title"
          name="title"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          className="input input-bordered !outline-none w-full"
        />
        <CSSTransition
          nodeRef={titleErrorRef}
          in={!!(touched.title && errors.title)}
          timeout={300}
          classNames="fade-scale"
          unmountOnExit
        >
          <div
            ref={titleErrorRef}
            className="text-red-500 text-sm font-medium pt-1"
          >
            {errors.title}
          </div>
        </CSSTransition>
      </div>

      <div className="flex flex-col md:flex-row gap-x-12 gap-y-4">
        <Checkbox
          id="attachmentsRequired"
          name="attachmentsRequired"
          label="Attachments Required"
          checked={values.attachmentsRequired}
          onChange={handleChange}
        />
        <Checkbox
          id="includeOptionalIntakeForm"
          name="includeOptionalIntakeForm"
          label="Include Optional Intake Form"
          checked={values.includeOptionalIntakeForm}
          onChange={handleIncludeOptionalChange}
        />
        <Checkbox
          id="asynchronous"
          name="asynchronous"
          label="Asynchronous Exam"
          checked={values.asynchronous}
          onChange={handleAsynchronousChange}
        />
      </div>

      <div className="mb-4 flex flex-col gap-2">
        <span className="font-medium">Questions</span>
        {mode === "add" ? (
          <div className="text-sm text-gray-500 mb-2">
            Note: You can add max 26 questions
          </div>
        ) : (
          <p className="text-text-hint font-medium">
            Drag to Reorder Questions
          </p>
        )}
        <DraggableExamQuestionsList
          questions={values.questions}
          onUpdateQuestions={handleUpdateQuestions}
          onRemoveQuestion={handleDeleteQuestion}
          onSaveQuestion={handleSaveQuestion}
          isEdited={mode === "edit"}
          isPendingDeleteQuestion={isPendingDeleteQuestion}
        />
        {mode === "edit" && (
          <button
            onClick={handleSaveOrder}
            className="btn btn-primary-outline mb-2 drop-shadow hover:text-base-100 w-max self-end"
          >
            Save Order
          </button>
        )}
        <CSSTransition
          nodeRef={questionRef}
          in={values.questions.length < MAX_QUESTIONS}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div ref={questionRef} className="flex flex-col gap-2">
            <input
              id="questionValue"
              name="questionValue"
              type="text"
              placeholder={mode === "edit" ? "Question" : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleQuestionKeyPress}
              value={values.questionValue}
              className="input input-bordered !outline-none w-full"
            />
            <button
              className="btn btn-primary-outline"
              type="button"
              onClick={handleAddQuestion}
            >
              {isPendingCreatedQuestion ? (
                <LuLoader2 className="w-5 h-5 animate-spin" />
              ) : (
                "Add Question"
              )}
            </button>
          </div>
        </CSSTransition>
      </div>

      <div className="flex">
        <div className="w-full">
          <div className="mb-1 font-medium">
            Medical Director Screening Instructions
          </div>
          <textarea
            id="note"
            name="note"
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.note || ""}
            placeholder="Medical Director Screening Instructions"
            className="textarea input-bordered !outline-none w-full !h-auto py-2"
          />
        </div>
      </div>

      <div className="mt-4 md:mt-0 md:self-end">
        <button
          type="submit"
          className="btn btn-primary w-full md:w-max hover:text-white !font-bold"
        >
          {isPending ? <LuLoader2 className="w-5 h-5 animate-spin mr-2" /> : ""}
          {mode === "add" ? "Add Exam" : "Save"}
        </button>
      </div>
    </form>
  );
};

export default ExamForm;
