/**
 * Replaces placeholders in a URL path with values from a provided parameters object.
 * Placeholders in the path should be in the format :key.
 *
 * @param path - The URL path containing placeholders, e.g., "doctor/assessments/:id/questions".
 * @param params - An object with keys and values to replace in the path, e.g., { id: "123" }.
 * @returns - The path with replaced values.
 */
export const replaceUrlParams = (path: string, params: Record<string, string>): string => {
  let updatedPath = path
  Object.entries(params).forEach(([key, value]) => {
    updatedPath = updatedPath.replace(`:${key}`, value)
  })
  return updatedPath
}
