import {
  AppointmentData,
  AppointmentResponse,
  AppointmentsListParams,
  AppointmentsListResponseData,
  ClientsListParams,
  ClientsListResponseData,
} from '../types'
import {handleRequest} from '../utils'
import {API_PATHS} from '../constants'

export const getAppointmentList = (params: AppointmentsListParams) => handleRequest<AppointmentsListResponseData>({
  method: 'GET',
  path: API_PATHS.appointments,
  params,
})

export const getAppointmentById = (id: string) => handleRequest<AppointmentResponse>({
  method: 'GET',
  path: `${API_PATHS.appointments}/${id}`,
})

export const getAppointmentByExternalId = (externalId: string) => handleRequest<AppointmentResponse>({
  method: 'GET',
  path: `${API_PATHS.patient_appointments}/${externalId}`,
})

export const updateAppointment = ({id, data}: {
  id: string,
  data: AppointmentData
}) => handleRequest<AppointmentResponse>({
  method: 'PUT',
  path: `${API_PATHS.appointments}/${id}`,
  data,
})

export const getClientsList = (params: ClientsListParams) => handleRequest<ClientsListResponseData>({
  method: 'GET',
  path: API_PATHS.clients,
  params,
})
