export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export const formatToCustomDateFormat = (dateStr: Date) => {
  const date = new Date(dateStr)

  const addLeadingZero = (value: number) => {
    return ('0' + value).slice(-2)
  }
  const year = date.getUTCFullYear()
  const month = addLeadingZero(date.getUTCMonth() + 1)
  const day = addLeadingZero(date.getUTCDate())

  return `${year}-${month}-${day}`
}
