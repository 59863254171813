export const debounce = <T extends (...args: any[]) => void>(func: T, timeout = 500): ((...args: Parameters<T>) => void) => {
  let timer: NodeJS.Timeout | null = null

  return (...args: Parameters<T>): void => {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
