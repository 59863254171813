import {FC, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {addDays} from 'date-fns'

interface DateRangePicker {
  startDate?: Date;
  endDate?: Date;
  onChange?: (dates: [Date | null, Date | null]) => void;
  onBlur?: (dates: [Date, Date]) => void;
}

const DateRangePicker: FC<DateRangePicker> = ({
  startDate: initialStartDate = null,
  endDate: initialEndDate = null,
  onChange,
  onBlur,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate)
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate)

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    onChange?.(dates)
  }

  const handleBlur = () => {
    if (startDate && !endDate) {
      setEndDate(startDate)
      onBlur?.([startDate, startDate])
    }
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={handleChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={addDays(new Date(), 0)}
      dateFormat='MMM d, yyyy'
      selectsRange
      onBlur={handleBlur}
      className='custom-datepicker'
      calendarClassName='custom-calendar'
    />
  )
}


export default DateRangePicker
