import {BsExclamationDiamond} from 'react-icons/bs'

const LinkExpiredPage = () => {
  return (
    <div className='flex flex-col items-center justify-center p-4 w-full gap-4 h-screen bg-bg-light'>
      <div className='w-24 h-24 p-4 rounded-full bg-error drop-shadow text-white'>
        <BsExclamationDiamond className='w-full h-full'/>
      </div>
      <div className='text-2xl font-medium'>Sorry this link is expired.</div>
    </div>
  )
}

export default LinkExpiredPage
