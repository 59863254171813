import {API_PATHS} from '../constants'
import {
  AssessmentActiveState,
  AssessmentData,
  AssessmentQuestionsData,
  AssessmentsListParams,
  AssessmentsListResponseData,
  CreatedAssessmentResponse,
  NewQuestion,
} from '../types'
import {handleRequest, replaceUrlParams} from '../utils'

export const getAssessmentsList = (params: AssessmentsListParams) => handleRequest<AssessmentsListResponseData>({
  method: 'GET',
  path: API_PATHS.assessments,
  params,
})

export const getAssessmentById = (id: string) => handleRequest<CreatedAssessmentResponse>({
  method: 'GET',
  path: `${API_PATHS.assessments}/${id}`,
})

export const createAssessment = (data: AssessmentData) => handleRequest<CreatedAssessmentResponse>({
  method: 'POST',
  path: API_PATHS.assessments,
  data,
})

export const updateAssessment = ({id, data}: {
  id: string,
  data: AssessmentData | AssessmentActiveState | AssessmentQuestionsData
}) => handleRequest({
  method: 'PUT',
  path: `${API_PATHS.assessments}/${id}`,
  data,
})

export const deleteAssessment = (id: string) => handleRequest({
  method: 'DELETE',
  path: `${API_PATHS.assessments}/${id}`,
})

export const createAssessmentQuestion = ({id, data}: { id: string, data: NewQuestion }) => {
  const endpoint = replaceUrlParams(API_PATHS.assessments_questions, {id})

  return handleRequest({
    method: 'POST',
    path: endpoint,
    data,
  })
}

export const deleteAssessmentQuestion = (params: { id: string, questionId: string }) => {
  const endpoint = replaceUrlParams(API_PATHS.assessments_questions_id, params)

  return handleRequest({
    method: 'DELETE',
    path: endpoint,
  })
}
