import axios from 'axios'
import Cookies from 'js-cookie'
import {NavigateFunction} from 'react-router-dom'
import {PAGES} from '../constants'

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // withCredentials: true,
})

apiClient.interceptors.request.use(
  config => {
    const token = Cookies.get('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

export const setupResponseInterceptors = (navigate: NavigateFunction) => {
  apiClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        Cookies.remove('token')
        navigate(PAGES.login)
      }

      return Promise.reject(error)
    },
  )
}

export const isResponseInterceptorInstalled = () => {
  const interceptors = apiClient.interceptors.response as any
  return !!interceptors.handlers.length
}
