import cn from 'classnames'
import {FC, ReactNode, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {IoClose, IoMenu} from 'react-icons/io5'
import {TbLogout} from 'react-icons/tb'
import {sidebarLinks} from '../constants/links'
import {Logo} from '../assets/images'
import {useLogout} from '../hooks'
import {SIDEBAR_COLLAPSED_KEY} from '../constants'

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({children}) => {
  const {logout} = useLogout()
  const {pathname} = useLocation()
  const currentLink = sidebarLinks.find(({path}) => path === pathname)

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = localStorage.getItem(SIDEBAR_COLLAPSED_KEY)
    return savedState === 'true'
  })

  useEffect(() => {
    localStorage.setItem(SIDEBAR_COLLAPSED_KEY, isCollapsed.toString())
  }, [isCollapsed])

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className='flex h-screen overflow-hidden'>
      <aside
        className={cn(
          'shrink-0 bg-accent-primary text-text-light transition-width duration-300',
          isCollapsed ? 'w-16 px-2' : 'w-56',
        )}
      >
        <div className='px-4 py-6'>
          <Logo className={cn('text-white', isCollapsed ? 'w-0' : 'w-full')}/>
        </div>
        <div className='flex items-center justify-between w-full p-3 mb-6'>
          {!isCollapsed && <div className='text-xl font-medium'>Dashboard</div>}
          <button onClick={toggleSidebar}>
            {isCollapsed ? <IoMenu className='w-6 h-6'/> : <IoClose className='w-6 h-6'/>}
          </button>
        </div>
        <ul className={cn('text-base font-medium w-full flex flex-col gap-1', isCollapsed ? 'gap-2' : '')}>
          {sidebarLinks.map(({name, path, icon}) => (
            <li
              key={name}
              className={cn(
                'cursor-pointer ml-3 rounded-l-xl', {
                  'hover:bg-item-hover': pathname !== path,
                  'bg-accent-light text-bg-dark hover:bg-accent-light': pathname === path,
                  '!ml-0 rounded-xl': isCollapsed,
                },
              )}
            >
              <Link
                to={path}
                className={cn('flex w-full items-center gap-2 p-3', {'justify-center': isCollapsed})}
              >
                {icon}
                {!isCollapsed && <div>{name}</div>}
              </Link>
            </li>
          ))}
        </ul>
      </aside>
      <main className='grow overflow-auto'>
        <div className='h-20'>
          <div
            className={cn(
              'bg-secondary-light-2 fixed z-20 top-0 px-4 md:px-8 py-3 shadow border-b flex items-center justify-between h-20 transition-width duration-300', {
                'w-[calc(100%-64px)]': isCollapsed,
                'w-[calc(100%-64px)] md:w-[calc(100%-224px)]': !isCollapsed,
              },
            )}
          >
            <div className='flex items-center gap-3 text-item-color'>
              <div className='text-[28px]'>{currentLink?.icon}</div>
              <span className='text-lg md:text-2xl font-medium'>{currentLink?.title}</span>
            </div>
            <div className='flex items-center justify-center gap-4'>
              <div className='hidden md:block'>
                {/*<button className='btn-primary gap-1 md:gap-2 w-max' disabled={true}>*/}
                {/*  <RiMailSendFill/>*/}
                {/*  Send Exam Invite*/}
                {/*</button>*/}
              </div>
              <button className='btn btn-primary gap-2' onClick={logout}>
                <TbLogout/>
                Logout
              </button>
            </div>
          </div>
        </div>
        <div className='p-4 md:p-8 flex flex-col gap-8 max-w-screen-xl w-full'>
          {children}
        </div>
      </main>
    </div>
  )
}

export default DashboardLayout
