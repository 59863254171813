import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {AppointmentAction, QUERY_KEYS} from '../constants'
import {AppointmentsListParams, ClientsListParams} from '../types'
import {
  getAppointmentByExternalId,
  getAppointmentById,
  getAppointmentList,
  getClientsList,
  updateAppointment,
} from '../api'

export const useAppointmentsList = (params: AppointmentsListParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.appointmentsList, params],
    queryFn: () => getAppointmentList(params),
  })
}

export const useAppointmentById = (id: string) => {
  return useQuery({
    queryKey: [],
    queryFn: () => getAppointmentById(id),
    enabled: !!id,
  })
}

export const useAppointmentByExternalId = (externalId: string) => {
  return useQuery({
    queryKey: [],
    queryFn: () => getAppointmentByExternalId(externalId),
    enabled: !!externalId,
  })
}

export const useUpdateAppointment = (option?: AppointmentAction) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAppointment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          option !== AppointmentAction.SendAnswers ? QUERY_KEYS.appointmentsList : '',
        ],
      })

      const message = option === AppointmentAction.SendAnswers
        ? 'Answers submitted'
        : 'Status updated'
      toast.success(message)
    },
  })
}

export const useClientsList = (params: ClientsListParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.clientsList, params],
    queryFn: () => getClientsList(params),
  })
}


