import {FC, useEffect} from 'react'
import Modal from '../index'
import ExamForm, {FullExamData} from '../../ExamForm'
import {useCreateAssessment} from '../../../hooks'

const options = [
  'Use the procedure/treatment name as the exam title.',
  'Ask yes or no questions only.',
  'Word questions so a "no" response indicates clearance, and "yes" indicates denial.',
  'Avoid open-ended questions that may reveal contraindications. Instead, list specific contraindications For example: If Amoxicillin and Doxycycline are contraindicated for your procedure "Are you currently taking any of the following medications: Amoxicillin, Doxycycline?"',
  'When asking about time-sensitive information, specify the time frame first. For instance, "In the last two weeks, have you experienced prolonged sun exposure?"',
  'When asking about the treatment area, start the question with "In the treatment area." For example, "In the treatment area, do you have any open wounds or active fungal infections?"',
  'If a question\'s response is a yes, but is not a contraindication, please save the exam, and click to view the exam. There will be a notes section for you to add information for your nurse practitioner to review. For example: Question is : "Are you currently taking aspirin?" - Within the note for that question once you click view, "Yes is not a contraindication, if yes please add "Patient is currently taking aspirin, monitor patient during procedure and use smallest gauge needle." - These notes are only visible to the provider.',
]

interface AddExamModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AddExamModal: FC<AddExamModalProps> = ({isOpen, closeModal}) => {
  const {
    mutate: createExam,
    isSuccess,
    isPending,
  } = useCreateAssessment()

  useEffect(() => {
    if (isSuccess) closeModal()
  }, [isSuccess])

  const handleSubmit = (fullData: FullExamData) => {
    const data = fullData
    if (!data.note) data.note = undefined
    createExam(data)
  }

  return (
    <Modal
      title='Add Exam'
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className='h-full'>
        <div className='flex flex-col gap-2 mb-2 md:mb-4 font-normal text-text-primary-2'>
          <div>
            To create an effective screening exam follow these guidelines:
          </div>
          <ol className='flex flex-col gap-1'>
            {options.map((option, i) => (
              <li key={i}>{i + 1}. {option}</li>
            ))}
          </ol>
        </div>
        <ExamForm mode='add' onSubmit={handleSubmit} isPending={isPending}/>
      </div>
    </Modal>
  )
}

export default AddExamModal
