import React, {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {FaUserPlus} from 'react-icons/fa'
import {LuLoader2} from 'react-icons/lu'
import AppointmentJitsiMeeting from '../AppointmentJitsiMeeting'
import PatientQuestionsForm from '../PatientQuestionsForm'
import {AppointmentStatus} from '../../constants'
import {useMessageHandler, useUpdateAppointment} from '../../hooks'

interface PatientMeetingRoomProps {
  jwtToken: string;
  roomName: string;
  assessmentId: string;
  patientName: string;
  id?: string;
}

const MeetingRoom: FC<PatientMeetingRoomProps> = ({
  id,
  patientName,
  assessmentId,
  jwtToken,
  roomName,
}) => {
  const navigate = useNavigate()
  useMessageHandler(navigate)
  const {
    mutate: updateAppointment,
    isPending: isPendingUpdateAppointment,
  } = useUpdateAppointment()

  const [isAnswersSubmitted, setIsAnswersSubmitted] = useState(false)
  const [isStatusChanged, setIsStatusChanged] = useState(false)
  const [actionInProgress, setActionInProgress] = useState<null | AppointmentStatus.Approved | AppointmentStatus.Declined>(null)

  useEffect(() => {
    if (!isPendingUpdateAppointment) {
      setActionInProgress(null)
    }
  }, [isPendingUpdateAppointment])

  const handleApprove = () => {
    setActionInProgress(AppointmentStatus.Approved)
    id && updateAppointment({id, data: {status: AppointmentStatus.Approved}})
  }

  const handleDecline = () => {
    setActionInProgress(AppointmentStatus.Declined)
    id && updateAppointment({id, data: {status: AppointmentStatus.Declined}})
  }

  return (
    <div className='font-medium h-screen w-full bg-white flex justify-center pt-14 md:pt-20'>
      <div
        className='bg-secondary-light-2 fixed z-20 top-0 px-4 md:px-8 py-3 shadow border-b h-14 md:h-20 w-full flex items-center'
      >
        <div className='max-w-[1312px] w-full mx-auto flex items-center justify-between'>
          <div className='flex items-center gap-3 text-item-color'>
            <FaUserPlus/>
            <span className='text-lg md:text-2xl font-medium'>Patient Meeting Room</span>
          </div>

          <div className='flex gap-5 items-center'>
            <button
              onClick={handleApprove}
              disabled={!isAnswersSubmitted || actionInProgress === AppointmentStatus.Declined}
              className='btn btn-success w-40 !font-bold flex items-center'
            >
              {actionInProgress === AppointmentStatus.Approved ?
                <LuLoader2 className='w-5 h-5 animate-spin mr-2'/> : ''}
              Approve
            </button>
            <button
              onClick={handleDecline}
              disabled={!isAnswersSubmitted || actionInProgress === AppointmentStatus.Approved}
              className='btn btn-error w-40 !font-bold flex items-center justify-center'
            >
              {actionInProgress === AppointmentStatus.Declined ?
                <LuLoader2 className='w-5 h-5 animate-spin mr-2'/> : ''}
              Decline
            </button>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full h-full px-4 pt-8 pb-4 md:pb-8'>
        <div className='w-full h-full overflow-hidden'>
          <div className='flex gap-8 justify-center w-full h-full overflow-y-auto'>
            <AppointmentJitsiMeeting
              id={id}
              patientName={patientName}
              roomName={roomName}
              jwtToken={jwtToken}
              onStatusChanged={setIsStatusChanged}
            />
            <PatientQuestionsForm
              id={id}
              assessmentId={assessmentId}
              isStatusChanged={isStatusChanged}
              onAnswersSubmitted={setIsAnswersSubmitted}
            />
          </div>
        </div>
        {/*<div className='text-gray-500 text-center py-4'>*/}
        {/*  <span className='text-error'>Note : </span>*/}
        {/*  If you are having audio issues or the call disconnects, our nurse will give you a call. Please accept a*/}
        {/*  call from an unknown number.*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default MeetingRoom
