import React, {FC, useState} from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import SortableItem from './SortableItem'
import {NewQuestion} from '../../types'

interface DraggableExamQuestionsListProps {
  questions?: NewQuestion[];
  onUpdateQuestions: (questions: NewQuestion[]) => void;
  onRemoveQuestion: (questionId: UniqueIdentifier) => void;
  onSaveQuestion: (questions: NewQuestion[]) => void;
  isEdited?: boolean;
  isPendingDeleteQuestion?: boolean;
}

const DraggableExamQuestionsList: FC<DraggableExamQuestionsListProps> = ({
  questions = [],
  onUpdateQuestions,
  onRemoveQuestion,
  onSaveQuestion,
  isEdited = false,
  isPendingDeleteQuestion = false,
}) => {
  const [activeId, setActiveId] = useState<null | UniqueIdentifier>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event
    setActiveId(null)

    if (over && active.id !== over.id) {
      const oldIndex = questions.findIndex((q) => q.id === active.id)
      const newIndex = questions.findIndex((q) => q.id === over.id)
      const reorderedQuestions = arrayMove(questions, oldIndex, newIndex)
      const updatedQuestions = reorderedQuestions.map((q, idx) => ({
        ...q,
        position: idx,
      }))
      onUpdateQuestions(updatedQuestions)
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id)
  }

  const handleRemove = (questionId: UniqueIdentifier) => {
    if (isEdited) {
      onRemoveQuestion(questionId)
      return
    }
    const updatedQuestions = questions.filter((q) => q.id !== questionId)
    onUpdateQuestions(updatedQuestions)
  }

  const handleUpdate = (id: UniqueIdentifier, value: string) => {
    const updatedQuestions = questions.map(q => q.id === id ? {...q, title: value} : q)
    onUpdateQuestions(updatedQuestions)
    onSaveQuestion(updatedQuestions)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={questions.map((q) => q.id)} strategy={verticalListSortingStrategy}>
        {questions.map((question, index) => (
          <SortableItem
            isPendingDeleteQuestion={isPendingDeleteQuestion}
            isEdited={isEdited}
            isActive={question.id === activeId}
            key={question.id}
            id={question.id}
            index={index}
            value={question.title}
            onRemove={handleRemove}
            onUpdate={handleUpdate}
          />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default DraggableExamQuestionsList
