import React, {ChangeEvent, FC, FocusEvent} from 'react'

interface PatientQuestionsFormItemProps {
  id: number;
  title: string;
  note: string | null;
  position: number;
  disabled: boolean;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: FocusEvent) => void;
}

const PatientQuestionsFormItem: FC<PatientQuestionsFormItemProps> = ({
  id,
  title,
  note,
  position,
  disabled,
  name,
  value,
  onChange,
  onBlur,
}) => {

  return (
    <div className='flex flex-col gap-2'>
      <label htmlFor={`${id}`}>
        {position + 1}. {title}
      </label>
      <textarea
        id={`${id}`}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        rows={2}
        className='textarea input-bordered !outline-none w-full leading-5'
      />
      {note && (
        <div>
          <div className='font-medium text-sm mb-2'>
            Reasons &amp; Exceptions :
          </div>
          <div
            className='text-sm break-words font-normal flex items-center justify-between gap-2 p-2 border border-border-light rounded-xl'
          >
            {note}
          </div>
        </div>
      )}
    </div>
  )
}

export default PatientQuestionsFormItem
