import {FC, ReactNode} from 'react'
import ReactModal from 'react-modal'
import {IoClose} from 'react-icons/io5'

ReactModal.setAppElement('#root')

const customContentStyles = {
  background: 'white',
  borderRadius: '0.5rem',
  top: '50%',
  left: '16px',
  right: '16px',
  bottom: 'auto',
  transform: 'translateY(-50%)',
  maxHeight: '90%',
  overflow: 'hidden',
  maxWidth: '840px',
  padding: '0',
  margin: '0 auto',
}

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  title: string;
  closeModal: () => void;
  style?: Record<string, string>;
}

const Modal: FC<ModalProps> = ({children, isOpen, title, closeModal, style}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{content: {...customContentStyles, ...style}}}
    >
      <div
        className='py-4 px-6 bg-secondary-light flex items-center justify-between shadow text-text-primary-2 relative z-50'
      >
        <h2 className='text-xl font-medium'>
          {title}
        </h2>
        <button className='btn-ghost -mr-1' onClick={closeModal}>
          <IoClose className='w-8 h-8'/>
        </button>
      </div>
      <div className='overflow-y-auto p-6' style={{maxHeight: 'calc(90vh - 64px)'}}>
        {children}
      </div>
    </ReactModal>
  )
}

export default Modal
