import {Link} from 'react-router-dom'
import {Certified, Dmca, Hipaa, Logo} from '../../assets/images'
import {footerLinks} from '../../constants/links'

const Footer = () => {
  return (
    <div className='w-full bg-[#FBFAFF] py-4 px-4 sm:px-8 xl:px-[60px] rounded-[20px]'>
      <div
        className='grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-items-center md:justify-items-stretch text-accent-primary-2'
      >
        <div className='order-1 md:order-1 col-span-1'>
          <Logo/>
        </div>
        <div className='order-3 md:order-2 col-span-1 flex items-center justify-center gap-2'>
          <Hipaa/>
          <Certified/>
          <Dmca/>
        </div>
        <ul className='order-2 md:order-3 col-span-1 flex gap-2 sm:gap-4 items-center justify-center'>
          {footerLinks.map(({label, link}) => (
            <li key={label}>
              <Link to={link} className='underline text-[13px] sm:text-[14px] xl:text-[15px]'>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Footer
