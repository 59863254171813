import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import {useLocation, useNavigate} from 'react-router-dom'
import {LuLoader2} from 'react-icons/lu'
import {CSSTransition} from 'react-transition-group'
import cn from 'classnames'
import {useLogin} from '../hooks'
import {LoginData, LoginError} from '../api'
import {PAGES} from '../constants'

const initialValues: LoginData = {
  email: '',
  password: '',
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
})

const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const emailErrorRef = useRef(null)
  const passwordErrorRef = useRef(null)
  const token = Cookies.get('token')
  const {mutate: performLogin, isPending} = useLogin()

  const [loginError, setLoginError] = useState<LoginError | null>(null)

  useEffect(() => {
    // Check if user is already logged in
    if (token) {
      // Redirect to results page or from where they came
      const from = (location.state as any)?.from?.pathname || PAGES.results
      navigate(from, {replace: true})
    }
  }, [token, navigate, location.state])

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: LoginData) => {
      performLogin(values, {
        onSuccess: (data) => {
          if ('errors' in data) {
            setLoginError(data.errors)
          } else if ('message' in data) {
            toast.error(data.message, {position: 'top-center'})
          } else {
            navigate(PAGES.results)
          }
        },
      })
    },
  })

  return (
    <div className='flex min-h-screen w-full items-center justify-center bg-bg-light'>
      <div
        className='relative w-full text-left ring-1 rounded-xl bg-white ring-border-light drop-shadow border-border-light p-6 max-w-md'
      >
        <div className='mb-6'>
          <p className='font-semibold text-3xl text-item-color mb-2'>
            Welcome to SkinnyRx
          </p>
          <p className='text-text-hint'>
            Please enter your details
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <input
              id='email'
              type='email'
              name='email'
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className='input input-bordered w-full'
            />
            <CSSTransition
              nodeRef={emailErrorRef}
              in={!!(touched.email && (errors.email || loginError?.email))}
              timeout={300}
              classNames='fade-scale'
              unmountOnExit
            >
              <div ref={emailErrorRef} className='text-text-error-2 text-sm pt-1 font-medium'>
                {errors.email || loginError?.email?.[0]}
              </div>
            </CSSTransition>
          </div>

          <div className='mb-4'>
            <input
              id='password'
              type='password'
              name='password'
              placeholder='Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className='input input-bordered w-full'
            />
            <CSSTransition
              nodeRef={passwordErrorRef}
              in={!!(touched.password && (errors.password || loginError?.password))}
              timeout={300}
              classNames='fade-scale'
              unmountOnExit
            >
              <div ref={passwordErrorRef} className='text-text-error-2 text-sm pt-1 font-medium'>
                {errors.password || loginError?.password?.[0]}
              </div>
            </CSSTransition>
          </div>
          <button
            type='submit' className={cn('btn btn-primary w-full', isPending ? 'cursor-not-allowed' : '')}
          >
            {isPending && <LuLoader2 className='w-5 h-5 mr-2 animate-spin'/>}
            LOGIN
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
