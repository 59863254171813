import {FC} from 'react'
import Dropdown from 'rc-dropdown'
import Menu, {Item as MenuItem} from 'rc-menu'
import {HiOutlineDotsVertical} from 'react-icons/hi'
import 'rc-dropdown/assets/index.css'
import 'rc-menu/assets/index.css'

interface ActionButtonProps {
  menuItems: { name: string, onClick: () => void, disabled?: boolean, }[];
}

const ActionButton: FC<ActionButtonProps> = ({menuItems}) => {
  const menu = (
    <Menu className='custom-dropdown-menu'>
      {menuItems.map((item, i) => (
        <MenuItem
          key={i}
          className='custom-dropdown-menu-item'
          onClick={item.onClick}
          disabled={item?.disabled}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <div className='p-1.5 rounded-full border cursor-pointer w-max hover:bg-secondary-light transition-all'>
        <HiOutlineDotsVertical/>
      </div>
    </Dropdown>
  )
}

export default ActionButton
