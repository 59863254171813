import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import PatientLayout from '../layouts/PatientLayout'
import Loader from '../components/Loader/DefaultLoader'
import JitsiMeetingComponent from '../components/JitsiMeetingComponent'
import {useAppointmentByExternalId, useMessageHandler} from '../hooks'
import {getParamFromURL} from '../utils'
import ProviderConnectionScreen from '../components/ProviderConnectionScreen'

const PatientMeetingPage = () => {
  const {externalId} = useParams()
  const navigate = useNavigate()
  useMessageHandler(navigate)

  const [patientName, setPatientName] = useState('')
  const [token, setToken] = useState('')
  const [roomName, setRoomName] = useState('')
  const [loading, setLoading] = useState(true)

  const {
    data,
    isFetching,
  } = useAppointmentByExternalId(externalId || '')

  useEffect(() => {
    if (!isFetching && data) {
      const roomName = getParamFromURL(data.invitation, 'room')
      const token = getParamFromURL(data.invitation, 'token')

      if (token) setToken(token)
      if (roomName) setRoomName(roomName)

      setPatientName(data.patientName)
    }
  }, [isFetching])

  return (
    <>
      <CSSTransition
        in={loading}
        timeout={300}
        classNames='fade'
        unmountOnExit
        onEntered={() => setLoading(false)}
      >
        <ProviderConnectionScreen/>
      </CSSTransition>

      <div className={loading ? 'disabled-content -z-50' : ''}>
        <PatientLayout patientName={patientName}>
          <div className='text-[#434242] leading-tight mb-[30px]'>
            <h2 className='text-[18px] sm:text-[20px] font-medium'>
              Consultation with licensed provider
            </h2>
            <div className='text-[14px] text-text-secondary-3'>
              from SkinnyRx
            </div>
          </div>
          <div className='w-full flex items-center justify-center h-[640px] transition-all'>
            {token && roomName ? (
              <JitsiMeetingComponent
                jwtToken={token}
                roomName={roomName}
                onDisplayNameChange={setPatientName}
                onLoaded={() => setLoading(false)}
              />
            ) : <Loader/>}
          </div>
        </PatientLayout>
      </div>
    </>
  )

}

export default PatientMeetingPage
