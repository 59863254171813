import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {RiDeleteBin7Line} from 'react-icons/ri'
import {BiEditAlt} from 'react-icons/bi'
import {LuLoader2} from 'react-icons/lu'
import {MdVisibility, MdVisibilityOff} from 'react-icons/md'
import Checkbox from '../../Checkbox'
import {AssessmentAction} from '../../../constants'
import {useUpdateAssessment} from '../../../hooks'
import {CreatedQuestion, QuestionNoteParams} from '../../../types'

interface QuestionNoteProps {
  examId: string;
  question: CreatedQuestion;
  note: null | QuestionNoteParams;
  onCancel: () => void;
}

const QuestionNote: FC<QuestionNoteProps> = ({examId, question, note, onCancel}) => {
  const noteTextareaRef = useRef<HTMLTextAreaElement>(null)

  const [noteValue, setNoteValue] = useState(note?.value ?? '')
  const [alwaysExpanded, setAlwaysExpanded] = useState(note?.visible ?? true)
  const [editValue, setEditValue] = useState(false)
  const [assessmentAction, setAssessmentAction] = useState<AssessmentAction | undefined>(undefined)

  const isNoteValueUnchanged = noteValue === note?.value
  const isVisibilityUnchanged = alwaysExpanded === note?.visible
  const isNoteEmpty = !noteValue.trim()

  const disabledSave = isNoteEmpty || isNoteValueUnchanged && isVisibilityUnchanged

  const {
    mutate: updateAssessment,
    isPending,
    isSuccess,
  } = useUpdateAssessment(assessmentAction)

  useEffect(() => {
    if (isSuccess) {
      setEditValue(false)
      onCancel()
    }
  }, [isSuccess])


  useEffect(() => {
    if ((editValue || !note) && noteTextareaRef.current) {
      noteTextareaRef.current.focus()
    }
  }, [editValue, note])

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value)
  }

  const handleSave = () => {
    setAssessmentAction(note?.value ? AssessmentAction.UpdateNote : AssessmentAction.AddNote)
    updateAssessment({
      id: examId, data: {
        questions: [{
          ...question, note: {
            value: noteValue,
            visible: alwaysExpanded,
          },
        }],
      },
    })
  }

  const handleDelete = () => {
    setAssessmentAction(AssessmentAction.RemoveNote)
    updateAssessment({
      id: examId, data: {
        questions: [
          {...question, note: {value: null}},
        ],
      },
    })
  }

  const handleCancel = () => {
    setNoteValue(note?.value ?? '')
    setAlwaysExpanded(note?.visible ?? true)
    setEditValue(false)
    onCancel()
  }

  const handleAlwaysExpanded = (e: ChangeEvent<HTMLInputElement>) => {
    setAlwaysExpanded(e.target.checked)
  }

  return (
    <div className='flex flex-col gap-2'>
      <div className='font-medium text-sm'>
        Reasons &amp; Exceptions :
      </div>
      {editValue || !note ? (
        <>
          <textarea
            ref={noteTextareaRef}
            value={noteValue}
            onChange={handleNoteChange}
            maxLength={2000}
            className='textarea input-bordered !outline-none textarea-sm w-full'
          />
          <label className='cursor-pointer w-max flex items-center gap-2'>
            <span className='text-sm'>Always expanded in Provider View</span>
            <Checkbox
              id={`${question.id}`}
              name='expanded'
              checked={alwaysExpanded}
              onChange={handleAlwaysExpanded}
            />
          </label>
          <div className='flex items-center gap-2'>
            <button
              className='btn btn-primary btn-sm'
              disabled={disabledSave}
              onClick={handleSave}
            >
              {isPending ? <LuLoader2 className='w-5 h-5 animate-spin'/> : 'Save'}
            </button>
            <button
              className='btn btn-ghost btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <div
          className='text-sm font-normal flex items-center justify-between gap-2 p-2 border border-border-light rounded-xl'
        >
          <div className='break-words'>{note?.value}</div>
          <div className='flex items-center gap-2'>
            {note?.visible
              ? <MdVisibility className='w-5 h-5'/>
              : <MdVisibilityOff className='w-5 h-5 -scale-x-100'/>
            }
            <button
              onClick={() => setEditValue(true)}
              className='btn-ghost !min-w-6 text-text-secondary'
            >
              <BiEditAlt className='w-5 h-5'/>
            </button>
            <button
              onClick={handleDelete}
              className='btn-ghost !min-w-6 text-error'
            >
              {isPending
                ? <LuLoader2 className='w-5 h-5 animate-spin'/>
                : <RiDeleteBin7Line className='w-5 h-5'/>
              }
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuestionNote
