import React, {FC, useEffect, useMemo, useState} from 'react'
import toast from 'react-hot-toast'
import {LuLoader2} from 'react-icons/lu'
import ActionButton from '../ActionButton'
import ConfirmCallModal from '../Modal/ConfirmCallModal'
import {useAppointmentById, useUpdateAppointment} from '../../hooks'
import {AppointmentStatus} from '../../constants'
import {getParamFromURL} from '../../utils'

interface ResultItemActionsProps {
  item: any;
}

const ResultItemActions: FC<ResultItemActionsProps> = ({item}) => {
  const [showConfirmCallModal, setShowConfirmCallModal] = useState(false)
  const [id, setId] = useState('')
  const [roomName, setRoomName] = useState('')

  const {
    data,
    isSuccess,
    isFetching,
  } = useAppointmentById(id)
  const {
    mutate: cancelPatientExam,
  } = useUpdateAppointment()

  useEffect(() => {
    if (data?.invitation) {
      const roomName = getParamFromURL(data.invitation, 'room')
      roomName && setRoomName(roomName)
    }
  }, [data])

  useEffect(() => {
    if (item.original.id === id) {
      if (isFetching) {
        toast(
          <div className='flex gap-2'>
            <LuLoader2 className='w-5 h-5 animate-spin'/>
            Connecting Now
          </div>, {duration: 1000},
        )
      }

      if (isSuccess) {
        setShowConfirmCallModal(true)
        setId('')
      }
    }
  }, [isFetching, isSuccess, id])

  const handleConnectNow = () => setId(item.original.id)

  const handleCancelExam = () => {
    cancelPatientExam({id: item.original.id, data: {status: AppointmentStatus.Cancelled}})
  }

  const actions = useMemo(() => [
    {name: 'Connect Now', onClick: handleConnectNow},
    {name: 'Cancel Exam', onClick: handleCancelExam},
  ], [])

  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <ActionButton menuItems={actions}/>
      </div>
      {showConfirmCallModal && (
        <ConfirmCallModal
          roomName={roomName}
          patientExamId={item.original.id}
          isOpen={showConfirmCallModal}
          closeModal={() => setShowConfirmCallModal(false)}
        />
      )}
    </>
  )
}

export default ResultItemActions
