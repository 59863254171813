import {AxiosError, AxiosResponse, isAxiosError} from 'axios'
import {apiClient} from './apiClient'
import {API_PATHS} from '../constants'

export interface LoginData {
  email: string;
  password: string;
}

export interface LoginError {
  email?: string[];
  password?: string[];
}

export type ErrorResponse = { errors: LoginError; } | { message: string; }

export interface SuccessResponse {
  data: { token: string };
}

export const login = async (data: LoginData): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse | ErrorResponse> = await apiClient.post<SuccessResponse | ErrorResponse>(API_PATHS.login, data)
    return response.data
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      const serverError = error as AxiosError<ErrorResponse>
      if (serverError && serverError.response) {
        return serverError.response.data
      }
    }
    throw new Error('An unexpected error occurred')
  }
}
