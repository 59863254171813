import {FC, useMemo, useState} from 'react'
import ActionButton from '../ActionButton'
import DeleteExamModal from '../Modal/DeleteExamModal'
import ExamDetailsModal from '../Modal/ExamDetailsModal'
import EditExamModal from '../Modal/EditExamModal'
import {useUpdateAssessment} from '../../hooks'
import {Action, AssessmentAction} from '../../constants'

interface ExamItemActionsProps {
  item: any;
}

const ExamItemActions: FC<ExamItemActionsProps> = ({item}) => {
  const {id, active} = item.original

  const [showExamDetails, setShowExamDetails] = useState(false)
  const [showEditExam, setShowEditExam] = useState(false)
  const [showDeleteExam, setShowDeleteExam] = useState(false)

  const {
    mutate: updateAssessment,
  } = useUpdateAssessment(active ? AssessmentAction.Deactivate : AssessmentAction.Activate)

  const handleView = () => setShowExamDetails(true)
  const handleEdit = () => setShowEditExam(true)
  const handleDelete = () => setShowDeleteExam(true)
  const handleDeactivate = () => {
    updateAssessment({id, data: {active: !active}})
  }

  const actions = useMemo(() => {
    const availableActions = item.original.actions || []

    const actionList = [
      {key: Action.View, name: 'View', onClick: handleView},
      {key: Action.Edit, name: 'Edit', onClick: handleEdit},
      {key: Action.Delete, name: 'Delete', onClick: handleDelete},
      {
        key: Action.ActivateDeactivate,
        name: active ? 'Deactivate Exam' : 'Activate Exam',
        onClick: handleDeactivate,
      },
    ]

    return actionList.filter(action => availableActions.includes(action.key))
  }, [item])

  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <ActionButton menuItems={actions}/>
      </div>
      {showExamDetails && (
        <ExamDetailsModal
          id={id}
          isOpen={showExamDetails}
          closeModal={() => setShowExamDetails(false)}
        />
      )}
      {showEditExam && (
        <EditExamModal
          id={id}
          isOpen={showEditExam}
          closeModal={() => setShowEditExam(false)}
        />
      )}
      {showDeleteExam && (
        <DeleteExamModal
          id={id}
          isOpen={showDeleteExam}
          closeModal={() => setShowDeleteExam(false)}
        />
      )}
    </>
  )
}

export default ExamItemActions
