import {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios'
import {apiClient} from '../api/apiClient'

interface RequestParams {
  method: Method;
  path: string;
  params?: Record<string, any>;
  data?: any;
}

export async function handleRequest<T>({method, path, params, data}: RequestParams): Promise<T> {
  const config: AxiosRequestConfig = {
    url: path,
    method,
    params,
    data,
  }

  try {
    const response: AxiosResponse<T> = await apiClient.request<T>(config)
    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    console.error(`API request error on ${path} with method ${method}:`, axiosError.message)
    throw axiosError
  }
}
