import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import InitialMeetingScreen from '../components/InitialMeetingScreen'
import MeetingRoom from '../components/MeetingRoom'
import {useAppointmentById} from '../hooks'
import {getParamFromURL} from '../utils'
import Loader from '../components/Loader/DefaultLoader'
import {PAGES} from '../constants'


const ScreenState = {
  INITIAL: 'initial',
  VIDEO_CONSULTATION: 'videoConsultation',
}

const DoctorMeetingPage = () => {
  const navigate = useNavigate()
  const {patientExamId, roomName: currentRoomName} = useParams<{ patientExamId: string, roomName: string }>()

  const [screenState, setScreenState] = useState(ScreenState.VIDEO_CONSULTATION)
  const [token, setToken] = useState('')
  const [roomName, setRoomName] = useState('')
  const [assessmentId, setAssessmentId] = useState('')

  const {
    data,
    isFetching,
  } = useAppointmentById(patientExamId || '')

  useEffect(() => {
    if (!isFetching && data) {
      const roomName = getParamFromURL(data.invitation, 'room')
      const token = getParamFromURL(data.invitation, 'token')

      if (roomName !== currentRoomName) {
        navigate(PAGES.linkExpired, {replace: true})
      } else {
        if (token) setToken(token)
        if (roomName) setRoomName(roomName)
        setAssessmentId(`${data.assessment.id}`)
      }
    }
  }, [isFetching])

  const handleStartClick = () => setScreenState(ScreenState.VIDEO_CONSULTATION)

  if (isFetching) return (
    <div className='w-screen h-screen flex items-center justify-center'>
      <Loader/>
    </div>
  )

  return (
    <>
      {screenState === ScreenState.INITIAL && (
        <InitialMeetingScreen onClick={handleStartClick}/>
      )}
      {screenState === ScreenState.VIDEO_CONSULTATION && (
        <MeetingRoom
          id={patientExamId}
          assessmentId={assessmentId}
          patientName={data?.patientName || ''}
          roomName={roomName}
          jwtToken={token}
        />
      )}
    </>
  )
}

export default DoctorMeetingPage
