import {MutationCache, QueryCache, QueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import toast from 'react-hot-toast'

const MAX_RETRY_ATTEMPTS = 3

const retryFn = (failureCount: number, error: Error) => {
  const axiosError = error as AxiosError
  if (axiosError.response?.status === 401) {
    return false
  }
  return axiosError.response?.status === 500 && failureCount < MAX_RETRY_ATTEMPTS
}

const handleError = (error: Error) => {
  const axiosError = error as AxiosError
  const data = axiosError.response?.data as any

  if (data?.message) toast.error(data.message)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: retryFn,
    },
    mutations: {
      retry: retryFn,
    },
  },
  queryCache: new QueryCache({
    onError: handleError,
  }),
  mutationCache: new MutationCache({
    onError: handleError,
  }),
})
