import {FC} from 'react'
import Modal from '../index'
import Loader from '../../Loader/DefaultLoader'
import QuestionList from '../../QuestionList'
import {useAssessmentById} from '../../../hooks'
import {CreatedAssessmentResponse} from '../../../types'

type DataKey = keyof CreatedAssessmentResponse;

interface ExamDetailsModalProps {
  id: string;
  isOpen: boolean;
  closeModal: () => void;
}

const details: { label: string; dataKey: DataKey }[] = [
  {label: 'Id', dataKey: 'id'},
  {label: 'Title', dataKey: 'title'},
  {label: 'Active Exam', dataKey: 'active'},
  {label: 'Attachments Required', dataKey: 'attachmentsRequired'},
  {label: 'Include Optional Intake Form', dataKey: 'includeOptionalIntakeForm'},
  {label: 'Asynchronous Exam', dataKey: 'asynchronous'},
]

const ExamDetailsModal: FC<ExamDetailsModalProps> = ({id, isOpen, closeModal}) => {
  const {
    data,
    isPending,
  } = useAssessmentById(id)

  const formatValue = (value: any, dataKey: DataKey) => {
    if (typeof value === 'boolean') {
      switch (dataKey) {
        case 'active':
          return value ? 'visible' : 'hidden'
        default:
          return value ? 'Yes' : 'No'
      }
    }
    return value || '-'
  }

  return (
    <Modal
      isOpen={isOpen}
      title='View Exam Details'
      closeModal={closeModal}
      style={{width: '600px'}}
    >
      <div>
        {isPending
          ? <Loader/> : data && (
          <div className='flex flex-col w-full gap-3'>
            <div className='w-full text-text-primary-2'>
              Adding a note will only be visible to the provider doing the screening. Please include any reasons for
              the
              questions, or if there are additional instructions. E.G Question: &quot;Do you have a B12
              Deficiency?&quot; In Note:
              &quot;Yes, is not a contraindication, please approve for IV, however in review notes please
              write, &quot;Patient has
              B12 Deficiency, Monitor during IV and use the smallest gauge needle.&quot;
            </div>
            {details.map(({label, dataKey}) => (
              <div
                key={label}
                className='flex flex-col gap-4 sm:flex-row w-full justify-between sm:items-center'
              >
                <div className='font-medium'>{label}</div>
                <div className='font-medium'>{formatValue(data[dataKey], dataKey)}</div>
              </div>
            ))}
            <div className='font-medium'>
              Medical Director Screening Instructions
            </div>
            <div className={data?.note ? 'border p-2 rounded-xl' : ''}>{data?.note || '-'}</div>
            {data?.questions && <QuestionList examId={id} questionList={data.questions}/>}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ExamDetailsModal
