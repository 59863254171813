import React, {FC, useEffect} from 'react'
import toast from 'react-hot-toast'
import {LuLoader2} from 'react-icons/lu'
import Modal from '../index'
import {useDeleteAssessment} from '../../../hooks'

interface DeleteExamModalProps {
  id: string;
  isOpen: boolean;
  closeModal: () => void;
}

const DeleteExamModal: FC<DeleteExamModalProps> = ({id, isOpen, closeModal}) => {
  const {
    mutate: deleteExam,
    isSuccess,
    isError,
    isPending,
  } = useDeleteAssessment()

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully deleted')
      closeModal()
    } else if (isError) {
      toast.error('Error')
    }
  }, [isSuccess, isError])

  const handleDelete = () => deleteExam(id)

  return (
    <Modal
      isOpen={isOpen}
      title='Delete Exam'
      closeModal={closeModal}
      style={{width: '400px'}}
    >
      <div className='flex flex-col gap-5'>
        <div className='font-medium'>
          Are you sure you want to delete this exam ?
        </div>
        <div className='flex items-center gap-4 self-end'>
          <button className='btn btn-primary-outline !font-bold' onClick={closeModal} disabled={isPending}>
            Cancel
          </button>
          <button className='btn btn-error !font-bold flex items-center justify-center gap-2' onClick={handleDelete}>
            {isPending ? <LuLoader2 className='w-5 h-5 animate-spin'/> : ''}
            Delete
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteExamModal
