import {FC, ReactNode} from 'react'
import {Logo} from '../assets/images'

interface PatientLayoutProps {
  children: ReactNode;
  patientName: string;
}

const PatientLayout: FC<PatientLayoutProps> = ({children, patientName}) => {
  return (
    <div className='mx-auto my-0 max-w-[1440px] pb-10 px-10'>
      <header className='pt-10 pb-4 sm:pb-[30px] flex-wrap flex justify-between gap-6 items-center'>
        <div className='justify-self-start'>
          <Logo className='text-accent-primary-2'/>
        </div>
        <div className='sm:justify-self-end flex gap-4 items-center text-[15px]'>
          <div className='text-[#434242]'>Your name:</div>
          <div className='font-medium max-w-60 truncate'>
            {patientName || '-'}
          </div>
        </div>
      </header>
      <main>
        {children}
      </main>
    </div>
  )
}

export default PatientLayout
