import {FC, useEffect} from 'react'
import Modal from '../index'
import ExamForm, {FullExamData} from '../../ExamForm'
import Loader from '../../Loader/DefaultLoader'
import {useAssessmentById, useUpdateAssessment} from '../../../hooks'

interface EditExamModalProps {
  id: string;
  isOpen: boolean;
  closeModal: () => void;
}

const EditExamModal: FC<EditExamModalProps> = ({id, isOpen, closeModal}) => {
  const {
    data,
    isPending: isPendingExamDetails,
    isFetching: isFetchingExamDetails,
  } = useAssessmentById(id)

  const {
    mutate: updateExam,
    isPending: isPendingUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateAssessment()

  useEffect(() => {
    if (isSuccessUpdate) closeModal()
  }, [isSuccessUpdate])

  const handleSubmit = (data: FullExamData) => {
    updateExam({id, data})
  }

  return (
    <Modal isOpen={isOpen} title='Edit Exam' closeModal={closeModal}>
      {isPendingExamDetails || isFetchingExamDetails
        ? <Loader/>
        : <ExamForm
          mode='edit'
          id={id}
          data={data}
          onSubmit={handleSubmit}
          isPending={isPendingUpdate || isFetchingExamDetails}
        />
      }
    </Modal>
  )
}

export default EditExamModal
