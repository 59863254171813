import {useState} from 'react'
import DashboardLayout from '../layouts/DashboardLayout'
import ResultsTable from '../components/ResultsTable'
import ClinicDropdown from '../components/ClinicDropdown'
import Loader from '../components/Loader/DefaultLoader'
import {Client} from '../types'

const ResultsPage = () => {
  const [clinic, setClinic] = useState<Client | null>(null)

  const handleChangeClinic = (clinic: Client) => {
    setClinic(clinic)
  }

  return (
    <DashboardLayout>
      <>
        <div className='flex flex-col md:flex-row items-center justify-between w-full gap-2 md:gap-8'>
          <div className='flex flex-col text-text-primary-3 font-medium'>
            <h1 className='text-xl md:text-3xl'>
              Patient Exams List
            </h1>
          </div>
          <div className='w-full md:w-max min-w-[240px] flex flex-col gap-1'>
            <ClinicDropdown onChange={handleChangeClinic}/>
          </div>
        </div>
        {clinic ? <ResultsTable clinicId={clinic.id}/> : <Loader/>}
      </>
    </DashboardLayout>
  )
}

export default ResultsPage
