import {FC, useState} from 'react'
import Table from '../Table'
import DateFilter from '../DateFilter'
import SearchField from '../SearchField'
import SortField from '../SortField'
import Loader from '../Loader/DefaultLoader'
import Pagination from '../Pagination'
import {useAppointmentsList} from '../../hooks'
import {AppointmentsListParams, PatientExamSortField} from '../../types'
import {columns} from './columns'
import {initialParams} from '../../constants'
import {formatToCustomDateFormat} from '../../utils'
import {addDays} from 'date-fns'

const today = new Date()
const sevenDaysAgo = addDays(today, -7)

interface ResultsTableProps {
  clinicId: number;
}

const ResultsTable: FC<ResultsTableProps> = ({clinicId}) => {
  const [params, setParams] = useState<AppointmentsListParams>({
    ...initialParams,
    client: clinicId,
    dateFrom: formatToCustomDateFormat(sevenDaysAgo),
    dateTo: formatToCustomDateFormat(today),
  })

  const {
    data,
    isPending,
    isFetching,
  } = useAppointmentsList(params)

  const handleSearch = (searchTerm: string) => {
    setParams(prev => {
      if (searchTerm) {
        return {...prev, search: searchTerm}
      }
      prev.search = undefined
      return {...prev}
    })
  }

  const handleSort = (value: PatientExamSortField) => {
    setParams(prev => ({...prev, sortBy: value}))
  }

  const handleDateFilter = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    if (start && end) {
      const dateFrom = formatToCustomDateFormat(start)
      const dateTo = formatToCustomDateFormat(end)
      setParams(prev => ({...prev, dateFrom, dateTo}))
    }
  }

  const handlePagination = (page: number) => {
    setParams(prev => ({...prev, page}))
  }

  return (
    <div className='flex flex-col p-1'>
      <div className='relative w-full text-left shadow-md ring-1 ring-border-light rounded-xl bg-white p-6'>
        <div className='mb-4 w-full flex flex-col lg:flex-row items-center gap-x-4 gap-y-2'>
          <div className='w-full lg:w-1/3 flex flex-col gap-1'>
            <SearchField onSearch={handleSearch}/>
          </div>
          <div className='w-full lg:w-2/3 flex flex-col sm:flex-row gap-4'>
            <div className='w-full sm:w-1/2 flex flex-col gap-1'>
              <SortField onSort={handleSort}/>
            </div>
            <div className='w-full sm:w-1/2 flex flex-col gap-1'>
              <DateFilter startDate={sevenDaysAgo} endDate={today} onChange={handleDateFilter}/>
            </div>
          </div>
        </div>

        <div className='pb-12'>
          <Table
            data={!isPending && !isFetching && data?.items ? data.items : []}
            columns={columns}
          />
        </div>

        {data?.items?.length
          ? <Pagination
            total={data.pagination.total}
            currentPage={data.pagination.currentPage}
            lastPage={data.pagination.lastPage}
            onPageChange={handlePagination}
          />
          : isPending || isFetching
            ? <Loader/>
            : (
              <div className='text-gray-300 font-semibold text-2xl py-8 text-center'>
                No patient exams recorded
              </div>
            )}
      </div>
    </div>
  )
}

export default ResultsTable
