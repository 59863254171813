import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {AssessmentAction, QUERY_KEYS} from '../constants'
import {
  createAssessment,
  createAssessmentQuestion,
  deleteAssessment,
  deleteAssessmentQuestion,
  getAssessmentById,
  getAssessmentsList,
  updateAssessment,
} from '../api'
import {AssessmentData, AssessmentsListParams} from '../types'

export const useAssessmentsList = (params: AssessmentsListParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.assessmentsList, params],
    queryFn: () => getAssessmentsList(params),
  })
}

export const useAssessmentById = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.assessment, id],
    queryFn: () => getAssessmentById(id),
    enabled: !!id,
  })
}

export const useCreateAssessment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: AssessmentData) => createAssessment(data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [QUERY_KEYS.assessmentsList]})
      toast.success('Add exam success')
    },
  })
}

export const useUpdateAssessment = (option?: AssessmentAction) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAssessment,
    onSuccess: () => {
      const assessmentAction = [
        AssessmentAction.AddNote,
        AssessmentAction.UpdateNote,
        AssessmentAction.RemoveNote,
        AssessmentAction.Order,
        AssessmentAction.SaveQuestion,
      ]
      queryClient.invalidateQueries({
        queryKey: [
          option && assessmentAction.includes(option) ? QUERY_KEYS.assessment : QUERY_KEYS.assessmentsList,
        ],
      })

      switch (option) {
        case AssessmentAction.Deactivate:
          toast.success('Exam Deactivated Successfully')
          break
        case AssessmentAction.Activate:
          toast.success('Exam Activated Successfully')
          break
        case AssessmentAction.AddNote:
          toast.success('Reasons & Exceptions added to question successfully')
          break
        case AssessmentAction.UpdateNote:
          toast.success('Reasons & Exceptions has been updated successfully')
          break
        case AssessmentAction.RemoveNote:
          toast.success('Reasons & Exceptions removed from question successfully')
          break
        default:
          toast.success('Successfully updated')
      }
    },
  })
}

export const useDeleteAssessment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteAssessment,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [QUERY_KEYS.assessmentsList]})
    },
  })
}

export const useCreateAssessmentQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createAssessmentQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [QUERY_KEYS.assessment]})
      toast.success('Question has been added successfully')

    },
  })
}

export const useDeleteAssessmentQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteAssessmentQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [QUERY_KEYS.assessment]})
      toast.success('Question has been deleted successfully')

    },
  })
}
