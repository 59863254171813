import {FC} from 'react'
import Dropdown from '../Dropdown'
import {PatientExamSortField} from '../../types'
import {SortBy} from '../../constants'

interface SortFieldProps {
  onSort: (value: PatientExamSortField) => void;
}

const items = [
  {name: 'Name', value: SortBy.PatientName},
  {name: 'Exam Title', value: SortBy.Title},
  {name: 'Date', value: SortBy.Date},
  {name: 'Status', value: SortBy.Status},
]

const SortField: FC<SortFieldProps> = ({onSort}) => {
  return (
    <>
      <p className='text-text-secondary-3 text-sm font-medium'>
        Sort By:
      </p>
      <Dropdown
        items={items}
        onChange={onSort}
        placeholder='Select Sort By'
      />
    </>
  )
}

export default SortField
