import {ChangeEvent, FC} from 'react'
import cn from 'classnames'

interface CheckboxProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  label?: string;
  className?: string;
}

const Checkbox: FC<CheckboxProps> = ({id, name, checked, label, className, onChange}) => {
  return (
    <label htmlFor={id} className={cn('flex items-center custom-checkbox gap-2 relative', className)}>
      <input
        id={id}
        name={name}
        type='checkbox'
        checked={checked}
        onChange={onChange}
        className='hidden-checkbox'
      />
      <div className='styled-checkbox'/>
      {label && <span className='font-medium cursor-pointer'>{label}</span>}
    </label>
  )
}

export default Checkbox
