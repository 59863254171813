import React, {FC} from 'react'
import {ColumnDef, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import cn from 'classnames'
import {AppointmentItem, AssessmentItem} from '../../types'

export type DataType = Record<string, unknown> | AssessmentItem | AppointmentItem;


interface TableProps<T extends DataType> {
  columns: ColumnDef<T>[];
  data: T[];
}

const Table: FC<TableProps<DataType>> = ({columns, data}) => {
  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <table className='w-full tabular-nums'>
      <thead className='text-left border-b border-border-light'>
      {tableInstance.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <th
              key={header.id} style={{width: header.getSize()}} className={cn('bg-secondary-light', {
              'rounded-tl-xl': !index,
              'rounded-tr-xl': index === headerGroup.headers.length - 1,
            })}
            >
              <div
                className={cn(
                  'sticky whitespace-nowrap font-semibold text-text-secondary top-0 px-4 py-3.5 bg-secondary-light text-center text-base', {
                    'rounded-tl-xl': !index,
                    'rounded-tr-xl': index === headerGroup.headers.length - 1,
                  })}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              </div>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody>
      {tableInstance.getRowModel().rows.map(row => (
        <tr key={row.id} className='text-text-secondary-3 font-medium border-b border-b-border-light'>
          {row.getVisibleCells().map(cell => (
            <td
              key={cell.id}
              className='align-middle tabular-nums p-4 undefined bg-white text-center max-w-md whitespace-normal text-sm'
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default Table
