import React, { FC, MouseEvent, useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { UniqueIdentifier } from "@dnd-kit/core";
import { AiOutlineEdit } from "react-icons/ai";
import { LuLoader2 } from "react-icons/lu";
import { RiDeleteBin7Line } from "react-icons/ri";
import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";

interface SortableItemProps {
  isPendingDeleteQuestion: boolean;
  isEdited: boolean;
  isActive: boolean;
  id: UniqueIdentifier;
  index: number;
  value: string;
  onRemove: (id: UniqueIdentifier) => void;
  onUpdate: (id: UniqueIdentifier, value: string) => void;
}

const SortableItem: FC<SortableItemProps> = ({
  isPendingDeleteQuestion,
  isEdited,
  isActive,
  id,
  index,
  value,
  onRemove,
  onUpdate,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [edit, setEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!isPendingDeleteQuestion) setIsDeleting(false);
  }, [isPendingDeleteQuestion]);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    zIndex: isActive ? 100 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setEdit(true);
  };

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDeleting(true);
    onRemove(id);
  };

  const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onUpdate(id, currentValue);
    setEdit(false);
  };

  const handleCancel = () => {
    setCurrentValue(value);
    setEdit(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log("e", e);

    if (e.key === "Enter") {
      e.preventDefault();

      onUpdate(id, currentValue);
      setEdit(false);
    }
  };

  return (
    <div
      style={style}
      {...attributes}
      className="draggable-element w-full bg-white shadow pr-4 rounded-xl border flex items-center justify-between cursor-default"
    >
      {edit ? (
        <div className="w-full pl-4 py-3">
          <div className="w-full flex items-center">
            {index + 1}.
            <input
              value={currentValue}
              onChange={({ target }) => setCurrentValue(target.value)}
              onKeyDown={handleKeyPress}
              className="w-full h-8 px-3 text-base !outline-none py-0 bg-gray-100 mx-2 rounded-xl"
            />
            <div className="flex items-center gap-3">
              <button className="text-btn-success" onClick={handleSave}>
                <FaRegCircleCheck className="w-6 h-6" />
              </button>
              <button className="text-error" onClick={handleCancel}>
                <FaRegCircleXmark className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            ref={setNodeRef}
            {...listeners}
            className="pl-4 mr-5 py-3 flex-grow font-medium cursor-move"
          >
            {`${index + 1}. ${value}`}
          </div>
          <div className="flex items-center gap-3">
            {isEdited && (
              <button onClick={handleEdit} className="btn-ghost !min-w-6">
                <AiOutlineEdit className="w-5 h-5" />
              </button>
            )}
            {isPendingDeleteQuestion && isDeleting ? (
              <LuLoader2 className="w-5 h-5 animate-spin text-red-500" />
            ) : (
              <button
                onClick={handleRemove}
                className="btn-ghost !min-w-6 text-red-500 hover:text-red-700"
              >
                <RiDeleteBin7Line className="w-5 h-5" />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SortableItem;
