import {FC} from 'react'
import Modal from '../index'
import {PAGES} from '../../../constants'
import {replaceUrlParams} from '../../../utils'

interface ConfirmCallModalProps {
  patientExamId: string;
  roomName: string;
  isOpen: boolean;
  closeModal: () => void;
}

const ConfirmCallModal: FC<ConfirmCallModalProps> = ({patientExamId, roomName, isOpen, closeModal}) => {
  return (
    <Modal
      isOpen={isOpen}
      title='Call Provider'
      closeModal={closeModal}
      style={{width: '400px'}}
    >
      <div className='flex flex-col gap-4'>
        <div className='font-medium text-base'>
          Confirm you would like to join call with provider now
        </div>
        <div className='flex items-center gap-4 self-end'>
          <a
            href={replaceUrlParams(PAGES.doctorMeeting, {patientExamId, roomName})}
            className='btn btn-primary w-max font-medium'
            target='_blank'
            rel='noopener noreferrer'
            onClick={closeModal}
          >
            Call Provider
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmCallModal
