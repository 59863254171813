import {FC, useCallback, useEffect} from 'react'
import JitsiMeetingComponent from '../JitsiMeetingComponent'
import {useUpdateAppointment} from '../../hooks'
import {AppointmentStatus} from '../../constants'

interface AppointmentJitsiMeetingProps {
  jwtToken: string;
  roomName: string;
  patientName: string;
  id?: string;
  onStatusChanged: (value: boolean) => void;
}

const AppointmentJitsiMeeting: FC<AppointmentJitsiMeetingProps> = ({
  jwtToken,
  roomName,
  patientName,
  id,
  onStatusChanged,
}) => {
  const {
    mutate: updateAppointment,
    isSuccess,
  } = useUpdateAppointment()

  useEffect(() => {
    if (isSuccess) {
      onStatusChanged(true)
    }
  }, [isSuccess])

  const handleVideoConferenceJoined = useCallback(() => {
    id && updateAppointment({id, data: {status: AppointmentStatus.Pending}})
  }, [])

  return (
    <div className='flex-1 flex flex-col items-end w-full h-full max-w-[640px]'>
      <h2 className='w-full text-center text-lg md:text-3xl font-semibold mb-3 text-text-primary-3'>
        {patientName}
      </h2>
      <div className='w-full flex items-center justify-center h-[640px] transition-all'>
        <JitsiMeetingComponent
          jwtToken={jwtToken}
          roomName={roomName}
          onVideoConferenceJoined={handleVideoConferenceJoined}
        />
      </div>
    </div>
  )
}

export default AppointmentJitsiMeeting
