import {useMutation} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
import {login, LoginData} from '../api'
import {PAGES} from '../constants'

export const useLogin = () => {
  return useMutation({
    mutationFn: (data: LoginData) => login(data),
    onSuccess: (data) => {
      if ('token' in data && typeof data.token === 'string') {
        Cookies.set('token', data.token, {
          expires: 1,
          secure: true,
          httpOnly: false,
          sameSite: 'strict',
        })
      }
    },
    onError: (error: Error) => {
      toast.error(error.message, {position: 'top-center'})
    },
  })
}

export const useLogout = () => {
  const navigate = useNavigate()

  const logout = () => {
    Cookies.remove('token')
    navigate(PAGES.login)
  }

  return {logout}
}
