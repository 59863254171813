import {FC} from 'react'
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io'
import {DEFAULT_PAGINATION} from '../../constants'

interface PaginationProps {
  total: number;
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({total, currentPage, lastPage, onPageChange}) => {
  const disabled = DEFAULT_PAGINATION.LIMIT > total
  const isPrevDisabled = currentPage <= 1
  const isNextDisabled = currentPage >= lastPage

  return (
    <div className='self-center flex w-full justify-center items-center gap-4'>
      <button
        type='button'
        disabled={disabled || isPrevDisabled}
        className='btn btn-sm gap-2 btn-ghost !text-gray-400 flex items-center justify-center'
        onClick={() => onPageChange(currentPage - 1)}
      >
        <IoIosArrowBack/>
        Prev
      </button>
      <button
        type='button'
        disabled={disabled || isNextDisabled}
        className='btn btn-sm gap-2 btn-ghost !text-gray-400 flex items-center justify-center'
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
        <IoIosArrowForward/>
      </button>
    </div>
  )
}

export default Pagination
