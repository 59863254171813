import {FC, useEffect, useState} from 'react'
import Dropdown from '../Dropdown'
import {initialParams} from '../../constants'
import {useClientsList} from '../../hooks'

interface ClinicDropdownProps {
  onChange: (value: any) => void;
}

const ClinicDropdown: FC<ClinicDropdownProps> = ({onChange}) => {
  const [params] = useState(initialParams)
  const [selectedItem, setSelectedItem] = useState('')

  const {
    data,
  } = useClientsList(params)

  useEffect(() => {
    if (data?.items?.length === 1) {
      setSelectedItem(data.items[0].name)
      onChange(data.items[0])
    }
  }, [data])

  return (
    <>
      <div className='font-medium text-text-secondary-3'>
        Select Clinic:
      </div>
      <Dropdown
        placeholder='Select Clinic'
        selected={selectedItem}
        items={data?.items || []}
      />
    </>
  )
}

export default ClinicDropdown
