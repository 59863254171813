import {BsClipboard2Data, BsClipboard2Plus} from 'react-icons/bs'
import {PAGES} from './pages'

export const sidebarLinks = [
  {name: 'Results', title: 'Patient Exams', icon: <BsClipboard2Data/>, path: PAGES.results},
  {name: 'Exams', title: 'Exams', icon: <BsClipboard2Plus/>, path: PAGES.exams},
]

export const footerLinks = [
  {
    label: 'Terms Of Service',
    link: '#',
  },
  {
    label: 'Contact Us',
    link: '#',
  },
  {
    label: 'Privacy Policy',
    link: '#',
  },
]
