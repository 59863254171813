import {FC} from 'react'
import {Logo} from '../../assets/images'

const examTitle = ' Test'
const questions = [
  {question: 'question 1'},
  {question: 'question 2'},
]

interface InitialMeetingScreenProps {
  onClick?: () => void;
}

const InitialMeetingScreen: FC<InitialMeetingScreenProps> = ({onClick}) => {
  return (
    <div className='p-6 lg:p-12 font-normal flex flex-col items-center justify-center gap-4'>
      <div className='mb-2 max-w-[240px] cursor-pointer'>
        <Logo className='w-60 h-14'/>
      </div>
      <div className='font-semibold text-lg lg:text-2xl'>
        {examTitle}
      </div>
      <div
        className='flex flex-col gap-2 p-4 bg-base-100 border rounded-xl max-h-[60vh] lg:max-h-[75vh] overflow-y-auto max-w-2xl'
      >
        <div className='font-semibold text-base'>
          Speed up your consultation and enhance your privacy by filling out the form below before connecting with a
          medical provider. This step is optional, but highly recommended for a more efficient and secure
          experience.
          Thank you for choosing SkinnyRx!
        </div>
        <div className='flex items-center justify-end'>
          <button
            onClick={onClick}
            className='btn btn-primary hover:text-base-100 w-fit !font-bold'
          >
            Skip &amp; Begin Video Consultation with Provider
          </button>
        </div>
        {questions.map(({question}, i) => (
          <div key={i} className='flex flex-col gap-2 w-full'>
            <div className='w-full'>
              {question}
            </div>
            <textarea rows={2} className='textarea input-bordered !outline-none w-full leading-5'/>
          </div>
        ))}
      </div>
      <div className='flex flex-col items-center justify-end gap-4'>
        <button
          onClick={onClick}
          className='btn btn-primary hover:text-base-100 w-full !font-bold'
        >
          Submit and Start Video Consultation
        </button>
      </div>
    </div>
  )
}

export default InitialMeetingScreen
