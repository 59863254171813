import {LuLoader2} from 'react-icons/lu'

const Loader = () => {
  return (
    <div className='flex items-center justify-center bg-white transition-opacity duration-300'>
      <LuLoader2 className='w-14 h-14 text-text-primary-2 animate-spin'/>
    </div>
  )
}
export default Loader
