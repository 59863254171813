export const SIDEBAR_COLLAPSED_KEY = 'collapsed'

export const MAX_QUESTIONS = 26

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  LIMIT: 10,
}

export const initialParams = {
  limit: DEFAULT_PAGINATION.LIMIT,
  page: DEFAULT_PAGINATION.PAGE,
}
