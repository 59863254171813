import {FC} from 'react'
import DateRangePicker from '../DateRangePicker'

interface DateFilterProps {
  onChange: (dates: [Date | null, Date | null]) => void;
  startDate?: Date;
  endDate?: Date;
}

const DateFilter: FC<DateFilterProps> = ({onChange, startDate, endDate}) => {
  return (
    <>
      <p className='text-text-secondary-3 text-sm font-medium'>
        Date Range:
      </p>
      <div className='min-w-[10rem] relative flex justify-between w-full'>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          onBlur={onChange}
        />
      </div>
    </>
  )
}

export default DateFilter
