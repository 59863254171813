import ResultItemActions from '../ResultItemActions'
import {formatDateString} from '../../utils'
import cn from 'classnames'
import {AppointmentStatus} from '../../constants'

export const columns = [
  {
    accessorKey: 'patientName',
    header: 'Patient Name',
    size: 200,
  },
  {accessorKey: 'title', header: 'Exam Title', size: 200},
  {
    accessorKey: 'status', header: 'Status', size: 120, cell: ({row}: any) => {
      const {New, Pending, Approved, Declined} = AppointmentStatus
      return (
        <div
          className={cn('rounded-xl inline-block px-2 border text-[13px]',
            [New, Pending, Approved, Declined].includes(row.original.status) ? {
              'border-bg-blue bg-bg-blue text-text-info-2': row.original.status === New,
              'border-bg-orange bg-bg-orange text-text-orange': row.original.status === Pending,
              'border-bg-green bg-bg-green text-text-info': row.original.status === Approved,
              'border-bg-red bg-bg-red text-text-error-2': row.original.status === Declined,
            } : 'border-bg-light bg-bg-light text-text-hint')}
        >
          {row.original.status}
        </div>
      )
    },
  },
  {
    accessorKey: 'date',
    header: 'Date',
    size: 200,
    cell: ({row}: any) => formatDateString(row.original.date),
  },
  {
    accessorKey: 'actions', header: 'Actions', size: 160, cell: ({row}: any) => {
      return [AppointmentStatus.Approved, AppointmentStatus.Declined].includes(row.original.status)
        ? '' : <ResultItemActions item={row}/>
    },
  },
]
