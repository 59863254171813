import {useEffect} from 'react'
import {NavigateFunction} from 'react-router-dom'
import {PAGES} from '../constants'

export const useMessageHandler = (navigate: NavigateFunction) => {
  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== window.location.origin && typeof event.data === 'string') {
        let data
        try {
          data = JSON.parse(event.data)
        } catch (error) {
          console.error('Error parsing message data:', error)
          return
        }

        if (data && data.params && data.params.type === 'event' &&
          data.params.data && data.params.data.name === 'error-occurred') {
          const errorMessage: string = data.params.data.error.message || ''

          if (errorMessage.includes('Token is expired') ||
            errorMessage.includes('claim is missing') ||
            errorMessage.includes('Not acceptable by exp')) {
            navigate(PAGES.linkExpired, {replace: true})
          }
        }
      }
    }

    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [navigate])
}
