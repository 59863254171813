import React, {FC, useEffect} from 'react'
import {useFormik} from 'formik'
import toast from 'react-hot-toast'
import {LuLoader2} from 'react-icons/lu'
import Loader from '../Loader/DefaultLoader'
import PatientQuestionsFormItem from './PatientQuestionsFormItem'
import {AppointmentAction} from '../../constants'
import {useAssessmentById, useUpdateAppointment} from '../../hooks'

interface PatientQuestionsFormProps {
  isStatusChanged: boolean;
  assessmentId: string;
  id?: string;
  onAnswersSubmitted: (value: boolean) => void;
}

const PatientQuestionsForm: FC<PatientQuestionsFormProps> = ({
  id,
  assessmentId,
  isStatusChanged,
  onAnswersSubmitted,
}) => {
  const {
    data,
    isPending: isPendingExamDetails,
    isFetching: isFetchingExamDetails,
  } = useAssessmentById(assessmentId)

  const {
    mutate: updateAppointment,
    isPending: isPendingUpdateAppointment,
    isSuccess: isSuccessUpdateAppointment,
  } = useUpdateAppointment(AppointmentAction.SendAnswers)

  useEffect(() => {
    if (isSuccessUpdateAppointment) {
      onAnswersSubmitted(true)
    }
  }, [isSuccessUpdateAppointment])

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
  } = useFormik({
    initialValues: {
      answers: data ? data.questions.map(question => ({questionId: question.id, value: ''})) : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const allAnswersGiven = values.answers.every(answer => answer.value.trim() !== '')
      if (!allAnswersGiven) {
        toast.error('Please provide answers to all questions.')
      } else {
        id && updateAppointment({id, data: values})
      }
    },
  })

  return (
    <div className='flex-1 w-full max-w-[640px] h-full'>
      {isPendingExamDetails || isFetchingExamDetails ? (
        <div className='h-full w-full flex items-center justify-center'>
          <Loader/>
        </div>
      ) : (data && (
        <>
          <h2 className='text-center text-lg md:text-3xl font-semibold mb-3 text-text-primary-3 truncate'>
            {data.title}
          </h2>
          <div className='min-h-[640px] h-[calc(100%-60px)] rounded-xl border shadow-md overflow-hidden'>
            <form
              onSubmit={handleSubmit}
              className='flex flex-col h-full gap-6 overflow-y-auto p-6'
            >
              {data.questions.map(({id, title, note, position}, index) => (
                <PatientQuestionsFormItem
                  key={id}
                  id={id}
                  title={title}
                  note={note?.visible ? note.value : null}
                  position={position}
                  disabled={!isStatusChanged}
                  name={`answers[${index}].value`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.answers[index]?.value || ''}
                />
              ))}
              <button
                type='submit'
                disabled={!isStatusChanged}
                className='btn btn-primary min-w-40 w-1/2 mt-4 self-center'
              >
                {isPendingUpdateAppointment ? <LuLoader2 className='w-5 h-5 animate-spin mr-2'/> : ''}
                Submit
              </button>
            </form>
          </div>
        </>
      ))}
    </div>
  )
}

export default PatientQuestionsForm
